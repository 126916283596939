<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0 text-capitalize">
          {{ isAdd ? `${$t("actions.add")} ${$t("luna.facestream.title")}` :`${$t("actions.edit")} ${$t("luna.facestream.title")}` }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Name Analytical -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('luna.facestream.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="itemData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider
            #default="validationContext"
            name="description"
            rules="required"
          >
            <b-form-group 
              class="text-capitalize"
              :label="$t('luna.facestream.description')" 
              label-for="description">
              <b-form-input
                id="description"
                v-model="itemData.description"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          
          <validation-provider
            #default="validationContext"
            name="camera"
            rules="required"
          >
            <b-form-group
              :label="$tc('camera.title', 2)"
              label-for="camera"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="cameraArray"
                :options="formattedOptions"
                :filterable="false"
                @search="onSearch"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="camera_proxy"
                @option:selected="optionSelect"
                @option:deselected="optionDeselect"
                multiple
              >
              
                <template #list-footer>
                  <li class="pagination">
                    <b-button
                      variant="outline-primary"
                      size="sm"
                      :disabled="!hasPrevPage"
                      @click="prevPage"
                    >
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </b-button>
                    <b-button
                      variant="outline-primary"
                      size="sm"
                      :disabled="!hasNextPage"
                      @click="nextPage"
                    >
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </b-button>
                  </li>
                </template>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit"
            >
              {{ $t("actions.save") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="text-capitalize"
            >
             {{$t('actions.cancel')}}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { ref, computed, watch, onMounted } from "@vue/composition-api";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { debounce } from "lodash";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import axios from "@axios";
import camera from '@/store/camera_manager/camera';

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
    multiple: { 
      type: Boolean, 
      required: false, 
      default: false 
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    };
  },
  setup(props, { emit }) {

    const cameraArray = ref([]);
    const { t } = useI18nUtils();
    const blankData = {
      name: null,
      description: null,
      camera_proxy: null,
    };

    const toast = useToast();

    const itemData = ref(JSON.parse(JSON.stringify(blankData)));
    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData));
    };

    const onSubmit = () => {
      let dispatch;

      if (props.isAdd) {
        itemData.value.camera_proxy = camerasProxy;
        dispatch = store.dispatch("luna_facestream/add", itemData.value);
        
      }

      dispatch
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            emit("refetch-data");
            emit("update:is-sidebar-active", false);
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetData);

    const loadData = () => {
      cameraArray.value = [];
    };

    const currentPage = ref(1);
    const search = ref(null);
    const meta = ref(null);
    const limit = ref(8);
    const options = ref([]);
    const camerasProxy = ref([]);
    const tempCameras = ref([]);

    onMounted(() => {
      fetchData();
    });

    const fetchData = () => {

      return new Promise((resolve, reject) => {

        let params = {
          q: search.value,
          max: limit.value,
          "page[n]": currentPage.value,
          sortBy: "id",
          sortDesc: true,
        }
        axios('v1/proxy_camera?deviceType=camera', { params } )
          .then((response) => {
            meta.value = response.data.meta.last_page;
            options.value = response.data.data;
            resolve();
          })
          .catch(() => {
            console.log("SelectPaginateAjax: Error obteniendo la información");
            reject();
          });
      });
    }
    const onSearch = debounce(async (query, loading) => {
      if (query.length) {
        loading(true);
        search.value = query;
        await fetchData();
        loading(false);
      } else {
        currentPage.value = 1;
        search.value = "";
        fetchData(query.value);
      }
    }, 500)

    const hasNextPage = computed(() => {
      if (meta.value) {
        return currentPage.value + 1 <= meta.value;
      }
      return false;
    });
    const formattedOptions = computed(() =>{
      return options.value.map((camera_proxy) => ({
        label: camera_proxy.camera.name == null ? null : camera_proxy.camera.name,
        value: camera_proxy.id,
      }));
    });
    const hasPrevPage = computed(() =>{
      return  currentPage.value - 1 > 0;
    });

    const nextPage = () => {
      currentPage.value += 1;
    };
    const prevPage = () => {
      currentPage.value --
    };

    const optionSelect = (selected) => {
      cameraArray.value = selected
      camerasProxy.value = selected.map(camera_proxy => (camera_proxy.value));
      tempCameras.value = cameraArray.value
    }
    const optionDeselect = (deselected) => {
      cameraArray.value = tempCameras.value.filter(camera_proxy => (camera_proxy.value != deselected.value))
      camerasProxy.value = cameraArray.value.map(camera_proxy => (camera_proxy.value));
      tempCameras.value = cameraArray.value
    }

    watch([currentPage], () => {
      fetchData();
    });

    return {
      itemData,
      onSubmit,
      cameraArray,
      refFormObserver,
      getValidationState,
      loadData,
      resetForm,

      formattedOptions,
      hasPrevPage,
      hasNextPage,
      prevPage,
      nextPage,
      currentPage,
      meta,
      search,
      onSearch,
      fetchData,
      options,
      optionSelect,
      optionDeselect,
      camerasProxy,
      tempCameras,
    };
  },
};
</script>

<style lang="scss">
.pagination {
  display: flex;
  justify-content: space-around;
  margin: 0.25rem 0.25rem 0;
}
.pagination button {
  flex-grow: 1;
  &:first-child {
    margin-right: 5px;
  }
}
.pagination button:hover {
  cursor: pointer;
}
</style>
